import CryptoIconWrapper from '@/components/CryptoIconWrapper';
import React from 'react';
import { getColorByCurrency, getIconByCurrency } from '@/utils/helpers';
import { cn } from '@/utils/style';

type Props = {
  fiatAmount: string | React.ReactNode;
  currency: string;
  className?: string;
  removeIcon?: boolean;
};

const FiatAmount: React.FC<Props> = ({
  fiatAmount,
  currency,
  className,
  removeIcon,
  ...props
}) => {
  return (
    <span className={cn(['flex items-center', className])}>
      <span className={cn(['mr-1 truncate', removeIcon && 'mr-0'])}>
        {fiatAmount}
      </span>
      {!removeIcon && (
        <span className="shrink-0">
          {getIconByCurrency(currency?.toLowerCase())}
        </span>
      )}
    </span>
  );
};

export default FiatAmount;
