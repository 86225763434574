import { useQuery } from '@tanstack/react-query';
import client from '@/utils/api';

const useSettings = () => {
  return useQuery({
    queryKey: ['playerSettings'],
    queryFn: () => {
      return client('player/settings');
    },
  });
};

export default useSettings;
