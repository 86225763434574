import { useFiatFromCents } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { Bonus } from '@/types/bonuses';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { getIconByCurrency } from '@/utils/helpers';
import CryptoTooltip from '@/components/CryptoTooltip';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { DateFormat } from '@/utils/date';

export const useMappedProps = (props: Bonus) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const issuedAt = dayjs(props.created_at).format(DateFormat.DATE);
  const validUntil = props.valid_until
    ? dayjs(props.valid_until).format(DateFormat.DATE)
    : null;
  const wager = parseFloat(
    (props.amount_wager_requirement_cents / props.amount_cents).toString(),
  ).toFixed(0);
  const validUntilTime = dayjs(validUntil);
  const currentTime = dayjs();

  const activatableUntil = dayjs(props.activatable_until).format(
    DateFormat.DATE,
  );
  const { formatCurrency } = useCurrencyFormatter();
  const fiatAmount = useFiatFromCents(props?.currency, props.amount_cents);
  const fiatWagerAmount = useFiatFromCents(
    props?.currency,
    props.amount_wager_cents,
  );
  const fiatWagerAmountRequirement = useFiatFromCents(
    props?.currency,
    props.amount_wager_requirement_cents,
  );

  const wagerAmountPercentage =
    props.amount_wager_requirement_cents !== 0
      ? (props.amount_wager_cents / props.amount_wager_requirement_cents) * 100
      : 100;

  return {
    currency: props?.currency,
    isValid: validUntilTime > currentTime,
    amount: viewFiat
      ? fiatAmount
      : formatCurrency(props?.amount_cents, props?.currency, {
          style: 'decimal',
        }),
    amountCrypto: formatCurrency(props?.amount_cents, props?.currency, {
      style: 'decimal',
    }),
    wager,
    wagerAmount: viewFiat
      ? fiatWagerAmount
      : formatCurrency(props.amount_wager_cents, props?.currency, {
          style: 'decimal',
        }),
    wagerAmountCrypto: formatCurrency(
      props.amount_wager_cents,
      props?.currency,
      { style: 'decimal' },
    ),
    wagerAmountPercentage: parseFloat(
      // @ts-ignore
      wagerAmountPercentage > 100 ? 100 : wagerAmountPercentage,
    ).toFixed(3),
    issuedAt,
    wagerAmountRequirement: viewFiat
      ? fiatWagerAmountRequirement
      : formatCurrency(props.amount_wager_requirement_cents, props?.currency, {
          style: 'decimal',
        }),
    wagerAmountRequirementCrypto: formatCurrency(
      props.amount_wager_requirement_cents,
      props?.currency,
      { style: 'decimal' },
    ),
    validUntil: validUntil ? validUntil : null,
    activatableUntil,
  };
};

export const useFormattedDescription = (
  amount: any,
  amountCrypto: any,
  currency: any,
) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const formattedDescription = useMemo(() => {
    return (
      <CryptoTooltip cryptoAmount={amountCrypto} cryptoCode={currency}>
        <div className="flex flex-row items-center">
          {amount}
          {viewFiat && (
            <span className="ml-1">{getIconByCurrency(currency)}</span>
          )}
        </div>
      </CryptoTooltip>
    );
  }, [amount, amountCrypto, currency, viewFiat]);

  return formattedDescription;
};

export const mapStageToStatus = (stage: string, activatable: boolean) => {
  switch (stage) {
    case 'wager_done':
      return 'Wager done';
    case 'lost':
      return 'Lost';
    case 'expired':
      return 'Expired';
    case 'activated':
      return 'Active';
    case 'played':
      return 'Played';
    case 'finished':
      return 'Played';
    case 'handle_bets':
      return activatable ? 'Not activated' : 'Active';
    case 'canceled':
      return 'Canceled';
    case 'wait':
      return 'Waiting';
    case 'issued':
      return 'Not Activated';
    default:
      return 'Loading';
  }
};

export const getStatusColorByStage = (stage: string) => {
  switch (stage) {
    case 'wager_done':
      return 'success';
    case 'lost':
      return 'warning';
    case 'expired':
      return 'error';
    case 'handle_bets':
      return 'success';
    case 'canceled':
      return 'error';
    case 'wait':
      return 'warning';
    case 'issued':
      return 'warning';
    default:
      return 'success';
  }
};

export const getImageUrlSelectBonus = (bonus: any) => {
  switch (bonus?.id) {
    case 'first_deposit':
      return '/assets/bonus-select/first-deposit.svg';
    case 'second_deposit':
      return '/assets/bonus-select/second-deposit.svg';
    case 'thired_deposit':
      return '/assets/bonus-select/thired-deposit.svg';
    case 'exclusive':
      return '/assets/bonus-select/exclusive.svg';
    case 'live_cashback':
      return '/assets/bonus-select/cashback.svg';
    case 'vip_cashback':
      return '/assets/bonus-select/vip.svg';
    case 'sportsbook':
      return '/assets/bonus-select/sports.svg';
    default:
      return '/assets/bonus-select/welcome.svg';
  }
};

export const calculateItemsToAdd = (n: number) => {
  return (4 - (n % 4)) % 4;
};

export const getBackgroundByType = (type: string) => {
  switch (type) {
    case 'first_deposit':
      return 'bg-[linear-gradient(97deg,#D15DA3_35.63%,#7013AA_75.86%)]';
    case 'second_deposit':
      return 'bg-[linear-gradient(102deg,#00CEAB_22.88%,#098983_73.07%)]';
    case 'third_deposit':
      return 'bg-[linear-gradient(104deg,#FFBF45_28.02%,#E7872E_70.16%)]';
    case 'vip_cashback':
      return 'bg-[linear-gradient(99deg,#B897FF_15.37%,#653FBF_78.84%)]';
    case 'sportsbook':
      return 'bg-[linear-gradient(103deg,#19AC29_18.42%,#503A95_70.45%)]';
    case 'live_cashback':
      return 'bg-[linear-gradient(98deg,#21B643_30.38%,#047650_72.31%)]';
    case 'exclusive':
      return 'bg-[linear-gradient(113deg,#305CCB_12.1%,#123982_77.34%)]';
    default:
      return 'bg-[linear-gradient(97deg,#D15DA3_35.63%,#7013AA_75.86%)]';
  }
};

export const pathByBonusId = (id: string) => {
  switch (id) {
    case 'first_deposit':
      return '?m=first-deposit-terms';
    case 'second_deposit':
      return '?m=second-deposit-terms';
    case 'third_deposit':
      return '?m=third-deposit-terms';
    case 'vip_cashback':
      return '?m=vip-terms';
    case 'sportsbook':
      return '?m=sportsbook-terms';
    case 'live_cashback':
      return '?m=live-cashback-terms';
    case 'bonus-wheel-prev':
      return '?m=wheel-terms';
  }
};
