import CryptoTooltip from '@/components/CryptoTooltip';
import { useAppSelector } from '@/hooks/redux';
import { Bonus } from '@/types/bonuses';
import { FreeSpin } from '@/types/freespins';
import { getIconByCurrency } from '@/utils/helpers';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

const useBonusStatus = (bonus: FreeSpin | Bonus) => {
  const now = dayjs();

  const bonusEnded = useMemo(() => {
    const endAt = dayjs(bonus.activatable_until);
    return endAt.diff(now) < 0 ? true : false;
  }, [bonus.activatable_until, now]);

  const bonusExpired = useMemo(() => {
    const endAt = dayjs(bonus.valid_until);
    return endAt.diff(now) < 0 ? true : false;
  }, [bonus.valid_until, now]);

  const inTheFuture = useMemo(() => {
    const endAt = dayjs(bonus.activatable_until);
    return now.diff(endAt) < 0 ? true : false;
  }, [bonus, now]);

  return {
    bonusEnded,
    bonusExpired,
    inTheFuture,
  };
};

const useFormattedDescription = (
  amount: string,
  amountCrypto: string,
  currency: string,
) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const formattedDescription = useMemo(() => {
    return (
      <CryptoTooltip cryptoAmount={amountCrypto} cryptoCode={currency}>
        <div tw="flex items-center">
          {amount}
          {viewFiat && <span tw="ml-1">{getIconByCurrency(currency)}</span>}
        </div>
      </CryptoTooltip>
    );
  }, [amount, amountCrypto, currency, viewFiat]);

  return formattedDescription;
};

export { useBonusStatus, useFormattedDescription };
