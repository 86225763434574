import { Button, Heading, ListComponent, Text } from '@purposeinplay/core-v2';
import React from 'react';
import { getIconByCurrency } from '@/utils/helpers';
import FiatAmount from '@/components/FiatAmount';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { cn } from '@/utils/style';
import { DateFormat } from '@/utils/date';
import useFiatFromCents from '@/hooks/fiat';
import dayjs from 'dayjs';
import { useActivatePlayerBonus, useCancelPlayerBonus } from '@/hooks/bonuses';
import { useToast } from '@purposeinplay/utils';
import Image from 'next/image';
import {
  getJackpotBgByIdentifier,
  getLabelColorByIdentifier,
} from '@/modules/jackpot/main-section/utils';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import RaysSVG from 'public/assets/jackpot/rays.svg';
import { removeAfterSpace } from '../utils';
type Props = {
  details: any;
  viewFiat: boolean;
};

const JackpotContent: React.FC<Props> = React.memo(
  ({ details, viewFiat = false }) => {
    const { t } = useTranslation();
    const content = t('notifications') as NotificationsContentType;
    const contentJackpot = content?.notification_types?.jackpot;
    const { formatCurrency } = useCurrencyFormatter();
    const { toast } = useToast();

    const fiatAmountCents = useFiatFromCents(
      details?.currency,
      details?.amount_cents,
    );

    const fiatAmountWagerRequirementCents = useFiatFromCents(
      details?.currency,
      details?.amount_wager_requirement_cents,
    );

    const identifier = details?.jackpot?.jackpot_level_external_code;
    const { mutateAsync: activateBonus, isPending: activateBonusLoading } =
      useActivatePlayerBonus();
    const { mutateAsync: cancelBonus } = useCancelPlayerBonus();
    return (
      <div className="flex flex-col items-center">
        <div className="relative z-10 flex flex-col items-center gap-0.5 text-center">
          <Heading as="h2" className="text-2xl font-bold leading-6">
            {content?.modal?.titles?.jackpot}
          </Heading>
          <Text size="base" className="font-normal leading-6 text-text-subdued">
            Fortune favors you today—your big win is here! Enjoy your
            well-earned rewards 💰✨
          </Text>
        </div>
        <RaysSVG className="absolute inset-0 left-1/2  -translate-x-1/2 fill-current text-bgr-lightest" />
        <div
          className={cn([
            'relative z-10 mb-4 flex flex-col items-center justify-center',
          ])}
        >
          <Image
            src={`assets/jackpot/${getJackpotBgByIdentifier(identifier, true)}-badge.png`}
            fill={false}
            width={identifier === 'level_1' ? 96 : 104}
            height={identifier === 'level_1' ? 96 : 104}
            alt="Jackpot badge box"
            loader={DEFAULT_IMAGE_LOADER}
          />
          <Text
            size="xl"
            as="span"
            className={cn([
              'font-semibold uppercase leading-7',
              getLabelColorByIdentifier(identifier),
            ])}
          >
            {details?.jackpot?.level_name}
          </Text>
        </div>
        <div className="relative z-10 mb-7 w-full">
          <ListComponent
            stylingOptions={{
              containerStyles:
                'hover:bg-surface-subdued hover:cursor-auto mb-2',
              valueStyles: 'text-right items-center flex justify-end',
              labelStyles: 'text-text-subdued',
            }}
            label={contentJackpot?.amount}
            value={
              viewFiat ? (
                <FiatAmount
                  fiatAmount={fiatAmountCents}
                  currency={details?.currency}
                />
              ) : (
                <span className="inline-flex items-center">
                  {removeAfterSpace(
                    formatCurrency(details?.amount_cents, details?.currency),
                  )}
                  &nbsp;
                  {getIconByCurrency(details?.currency.toLowerCase())}
                </span>
              )
            }
          />
          <ListComponent
            stylingOptions={{
              containerStyles:
                'hover:bg-surface-subdued hover:cursor-auto mb-2',
              valueStyles: 'text-right items-center flex justify-end',
              labelStyles: 'text-text-subdued',
            }}
            label={contentJackpot?.wager}
            value={
              viewFiat ? (
                <FiatAmount
                  fiatAmount={fiatAmountWagerRequirementCents}
                  currency={details?.currency}
                />
              ) : (
                <span className="inline-flex items-center">
                  {removeAfterSpace(
                    formatCurrency(
                      details?.amount_wager_requirement_cents,
                      details?.currency,
                    ),
                  )}
                  &nbsp;
                  {getIconByCurrency(details?.currency.toLowerCase())}
                </span>
              )
            }
          />
          <ListComponent
            stylingOptions={{
              containerStyles:
                'hover:bg-surface-subdued hover:cursor-auto mb-2',
              valueStyles: 'text-right items-center flex justify-end',
              labelStyles: 'text-text-subdued',
            }}
            label={contentJackpot?.expire_date}
            value={dayjs(details?.activatable_until)
              .utc()
              .format(DateFormat.DATE_WITH_TIME)}
          />
        </div>
        <div className="flex w-full items-center">
          <Button
            className="mr-2 w-full"
            color="tertiary"
            onClick={() => {
              cancelBonus(details?.id);
            }}
          >
            Cancel
          </Button>
          <Button
            className="w-full"
            color="primary"
            isLoading={activateBonusLoading}
            disabled={activateBonusLoading || !details?.activatable}
            onClick={(e) => {
              e.stopPropagation();
              // onClick && onClick();
              if (!details?.activatable) return;
              return activateBonus(details?.id).then(() =>
                toast({
                  state: 'success',
                  title: 'Bonus activated',
                }),
              );
            }}
          >
            Activate
          </Button>
        </div>
      </div>
    );
  },
);

JackpotContent.displayName = 'JackpotContent';

export default JackpotContent;
