export const getLabelColorByIdentifier = (identifier: string) => {
  switch (identifier) {
    case 'level_1':
      return 'text-[#FFB000]';
    case 'level_3':
      return 'text-[#E9A77D]';
    case 'level_2':
      return 'text-[#B3B7DE]';
    default:
      return 'text-[#E9A77D]';
  }
};

export const getJackpotBgByIdentifier = (
  identifier: string,
  isModal = false,
) => {
  switch (identifier) {
    case 'level_3':
      return 'jackpot-box-major';
    case 'level_1':
      return isModal ? 'jackpot-mega' : 'jackpot-box-mega';
    case 'level_2':
      return 'jackpot-box-grand';
    default:
      return 'jackpot-box-major';
  }
};

export const getJackpotModalBgByIdentifier = (identifier: string) => {
  switch (identifier) {
    case 'level_3':
      return 'bg-gradient-to-b from-[#F6F6F6] to-[#DCA888] dark:from-[#192C3D] dark:to-[#AA6A43]';
    case 'level_1':
      return 'bg-gradient-to-b from-[#F6F6F6] to-[#E3C990] dark:from-[#192C3D] dark:to-[#AE904B]';
    case 'level_2':
      return 'bg-gradient-to-b from-[#F6F6F6] to-[#D7DCFF] dark:from-[#192C3D] dark:to-[#6C6E80]';
    default:
      return 'bg-gradient-to-b dark:from-[#192C3D] dark:to-[#AA6A43]';
  }
};

export const getJackpotTypeByIdentifier = (identifier: string) => {
  switch (identifier) {
    case 'level_3':
      return 'major';
    case 'level_1':
      return 'mega';
    case 'level_2':
      return 'grand';
    default:
      return 'major';
  }
};
