import React, { useCallback, useMemo } from 'react';
import dayjs from '@/utils/dayjsconfig';
const { useState, useEffect } = React;

export interface StateProps {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
}

interface Props {
  date: string;
  short?: boolean;
  enabled?: boolean;
  tournament?: any;
}

const useCountdown = ({ date, short = false, enabled = true }: Props) => {
  const countdownDate = new Date(dayjs(date).unix() * 1000).getTime();
  const [state, setState] = useState<StateProps>();
  const ended = useMemo(() => {
    const now = dayjs();
    const endAt = dayjs(date);
    return dayjs(endAt).isBefore(now);
  }, [date]);

  const setNewTime = React.useCallback(() => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days: string | number = Math.floor(
        distanceToDate / (1000 * 60 * 60 * 24),
      );
      let hours: string | number = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      let minutes: string | number = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      let seconds: string | number = Math.floor(
        (distanceToDate % (1000 * 60)) / 1000,
      );

      days = `${days}`;
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
    }
  }, [countdownDate]);

  useEffect(() => {
    if (!enabled) return;
    const intervalId = setInterval(() => {
      if (ended) {
        clearInterval(intervalId);
      } else {
        setNewTime();
      }
    }, 1000);
    if (ended) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [setNewTime, ended, enabled]);

  const memoizedState = useMemo(
    () => ({
      days: state?.days,
      hours: state?.hours,
      minutes: state?.minutes,
      seconds: state?.seconds,
    }),
    [state?.days, state?.hours, state?.minutes, state?.seconds],
  );

  const checkDay = useCallback(() => {
    if (Number(memoizedState?.days) === 0 || undefined) {
      return '';
    } else if (Number(memoizedState?.days) === 1) {
      return `${memoizedState?.days} day`;
    } else {
      return `${memoizedState?.days} days`;
    }
  }, [memoizedState?.days]);

  const hasNoState =
    !memoizedState?.days &&
    !memoizedState?.hours &&
    !memoizedState?.minutes &&
    !memoizedState?.seconds;

  return {
    state: memoizedState,
    short,
    checkDay,
    ended,
    hasNoState,
  };
};

export default useCountdown;
