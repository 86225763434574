import { Heading, ListComponent, Text } from '@purposeinplay/core-v2';
import dayjs from 'dayjs';
import React from 'react';
import { getIconByCurrency } from '@/utils/helpers';
import FiatAmount from '@/components/FiatAmount';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { getSnackbarIconStyle } from '@/components/MediaComponent/utils';
import { cn } from '@/utils/style';
import { DateFormat } from '@/utils/date';
import { removeAfterSpace } from '../utils';

type Props = {
  icon: React.ReactNode;
  details: any;
  viewFiat: boolean;
  fiatAmount: string;
};

const PaymentsContent: React.FC<Props> = React.memo(
  ({ icon, details, viewFiat = false, fiatAmount = '' }) => {
    const { t } = useTranslation();
    const content = t('notifications') as NotificationsContentType;
    const paymentsContent = content?.notification_types?.payments;
    const { formatCurrency } = useCurrencyFormatter();
    return (
      <div className="flex flex-col items-center">
        <div
          className={cn([
            'relative mb-spacing-sm mr-spacing-xs flex h-10 w-10 items-center justify-center rounded-radius-md p-padding-sm',
            getSnackbarIconStyle('success'),
          ])}
        >
          {icon}
        </div>
        <Heading as="h2" className="mb-7 text-xl font-medium">
          {details?.action === 'deposit'
            ? paymentsContent?.deposit_info
            : paymentsContent?.withdrawal_info}
        </Heading>
        <ListComponent
          stylingOptions={{
            containerStyles: 'hover:bg-surface-subdued hover:cursor-auto mb-2',
            valueStyles: 'text-right items-center flex justify-end',
            labelStyles: 'text-text-subdued',
          }}
          label={paymentsContent?.amount}
          value={
            viewFiat ? (
              <FiatAmount
                fiatAmount={fiatAmount}
                currency={details?.currency}
              />
            ) : (
              <span className="inline-flex items-center">
                {removeAfterSpace(
                  formatCurrency(details?.amount_cents, details?.currency, {
                    style: 'decimal',
                  }),
                )}
                &nbsp;
                {getIconByCurrency(details?.currency.toLowerCase())}
              </span>
            )
          }
        />

        <ListComponent
          stylingOptions={{
            containerStyles: 'hover:bg-surface-subdued hover:cursor-auto',
            valueStyles: 'text-right',
            labelStyles: 'text-text-subdued',
          }}
          label={paymentsContent?.date}
          value={dayjs(details?.finished_at).format(DateFormat.DATE_WITH_TIME)}
        />
      </div>
    );
  },
);

PaymentsContent.displayName = 'PaymentsContent';

export default PaymentsContent;
