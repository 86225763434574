import React, { useMemo } from 'react';
import useCountdown from '@/hooks/use-countdown';
import dayjs from 'dayjs';
import { Badge } from '@purposeinplay/core-v2';
import { getStatusColorByStage } from '@/components/Bonuses/utils';
import { cn } from '@/utils/style';
import Timeleft from '@/components/Timeleft';
import { mapStageToStatus } from '../utils';

const CountdownBonuses: React.FC<{
  extraSmall?: boolean;
  bonusEnded?: boolean;
  bonusExpired?: boolean;
  details?: any;
  invert?: boolean;
  className?: string;
}> = ({
  extraSmall = false,
  bonusEnded,
  bonusExpired,
  details,
  invert = false,
  className,
  ...rest
}) => {
  const isBonusValid = !bonusEnded && !bonusExpired && details?.valid_until;
  const isBonusActivable =
    !bonusEnded &&
    !bonusExpired &&
    !details?.valid_until &&
    details?.activatable_until;

  const { state, ended } = useCountdown({
    short: false,
    date: isBonusValid
      ? details?.valid_until
      : isBonusActivable
        ? details?.activatable_until
        : dayjs(details?.activatable_until),
  });
  const titleTag = mapStageToStatus(details?.stage, details?.activatable);

  return (
    <div
      className={cn([
        'flex flex-col items-start justify-around p-3',
        className,
      ])}
      {...rest}
    >
      <div className="relative flex flex-col items-start">
        <Badge
          type={getStatusColorByStage(details?.stage)}
          size="md"
          className={cn([extraSmall && `-top-2.5 left-0 px-1 py-0 text-[9px]`])}
        >
          {titleTag}
        </Badge>
        {details?.stage === 'canceled' ? (
          <Timeleft
            extraSmall={extraSmall}
            small
            invert={invert}
            units={{
              days: '00',
              hours: '00',
              minutes: '00',
              seconds: '00',
            }}
            isLoading={state === undefined}
          />
        ) : (
          <Timeleft
            extraSmall={extraSmall}
            small
            invert={invert}
            units={{
              days: state?.days,
              hours: state?.hours,
              minutes: state?.minutes,
              seconds: state?.seconds,
            }}
            isLoading={state === undefined}
          />
        )}
      </div>
    </div>
  );
};

export default CountdownBonuses;
