import React, { useMemo, useState } from 'react';
import CountdownBonuses from './countdown-bonuses';
import {
  useActivatePlayerBonus,
  useActivatePlayerFreeSpins,
  useCancelPlayerBonus,
  useCancelPlayerFreeSpins,
} from '@/hooks/bonuses';
import { useBonusStatus } from '../hooks';
import { Button, Heading, ListComponent, Text } from '@purposeinplay/core-v2';
import { useQueryClient } from '@tanstack/react-query';
import FiatAmount from '@/components/FiatAmount';
import { getIconByCurrency } from '@/utils/helpers';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useToast } from '@purposeinplay/utils';
import { cn } from '@/utils/style';
import { getSnackbarIconStyle } from '@/components/MediaComponent/utils';
import { removeAfterSpace } from '../utils';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';

type Props = {
  icon: React.ReactNode;
  onClick?: () => void;
  details: any;
  viewFiat?: boolean;
  fiatAmount: string;
};

const FreeSpinsContent: React.FC<Props> = React.memo(
  ({ icon, onClick, details, viewFiat = false, fiatAmount = '' }) => {
    const { t } = useTranslation();
    const content = t('notifications') as NotificationsContentType;
    const bonusContent = content?.notification_types?.bonus;
    const toastMessages = t('notifications')
      ?.other_toast_messages as NotificationsContentType['other_toast_messages'];
    const { bonusEnded, bonusExpired } = useBonusStatus(details);

    const { formatCurrency } = useCurrencyFormatter();

    const { mutateAsync: activateSpins, isPending: activateLoading } =
      useActivatePlayerFreeSpins();
    const { mutateAsync: activateBonus, isPending: activateBonusLoading } =
      useActivatePlayerBonus();
    const { mutateAsync: cancelBonus } = useCancelPlayerBonus();
    const { mutateAsync: cancelFreespins } = useCancelPlayerFreeSpins();

    const [readMore, setReadMore] = useState(false);

    const gamesList = useMemo(() => {
      const gameKeys = details?.games_info?.map((game: any) => game.title);
      return gameKeys ? (
        <div>
          <Text size="sm" className="mb-1">
            {readMore
              ? gameKeys.join(', ')
              : `${gameKeys.slice(0, 5)} ${gameKeys.length > 5 ? `...` : ''}`}
          </Text>
          {gameKeys.length > 5 && (
            <Button
              ghost
              size="xs"
              color="tertiary"
              className="ml-auto"
              onClick={() => setReadMore(!readMore)}
            >
              {readMore
                ? bonusContent?.show_less
                : `+${gameKeys.length - 5} ${bonusContent?.show_more}`}
            </Button>
          )}
        </div>
      ) : null;
    }, [
      bonusContent?.show_less,
      bonusContent?.show_more,
      details?.games_info,
      readMore,
    ]);
    const queryClient = useQueryClient();
    const { toast } = useToast();

    return (
      <div className="flex flex-col items-center">
        <div
          className={cn([
            'relative mx-auto mb-spacing-sm mr-spacing-xs flex h-10 w-10 items-center justify-center rounded-radius-md p-padding-sm',
            getSnackbarIconStyle('success'),
          ])}
        >
          {icon}
        </div>
        <div className="flex w-full flex-col items-center justify-center space-y-7">
          <Heading as="h2" className="text-xl font-medium">
            {details?.title}
          </Heading>

          <CountdownBonuses
            extraSmall
            invert={false}
            bonusEnded={bonusEnded}
            bonusExpired={bonusExpired}
            details={details}
            className="justify-center space-y-4 p-0"
          />
          <div className="w-full space-y-spacing-2xs">
            <ListComponent
              stylingOptions={{
                containerStyles:
                  'hover:bg-surface-subdued hover:cursor-auto bg-surface-subdued',
                valueStyles: 'text-right flex justify-end',
                labelStyles: 'text-text-subdued',
              }}
              label={bonusContent?.amount}
              value={
                details?.freespins_total ? (
                  `${details?.freespins_total}  FS`
                ) : viewFiat ? (
                  <FiatAmount
                    fiatAmount={fiatAmount}
                    currency={details?.currency}
                  />
                ) : (
                  <span className="inline-flex items-center">
                    {formatCurrency(details?.amount_cents, details?.currency, {
                      style: 'decimal',
                    })}
                    {getIconByCurrency(details?.currency.toLowerCase())}
                  </span>
                )
              }
            />

            <ListComponent
              stylingOptions={{
                containerStyles: cn([
                  'hover:bg-surface-subdued hover:cursor-auto bg-surface-subdued',
                  !details?.games_info && '!border-none',
                ]),
                valueStyles: 'text-right',
                labelStyles: 'text-text-subdued',
              }}
              label={bonusContent?.type}
              value={
                details?.freespins_total
                  ? bonusContent?.fs_type
                  : bonusContent?.bonus_type
              }
            />
            {details?.games_info !== undefined && (
              <ListComponent
                stylingOptions={{
                  containerStyles: 'hover:bg-transparent hover:cursor-auto',
                  valueStyles: 'text-right',
                  labelStyles: 'text-text-subdued',
                }}
                label={bonusContent?.games}
                value={gamesList}
              />
            )}
          </div>
          <div className="flex w-full items-center">
            <Button
              className="mr-2 w-full"
              color="tertiary"
              onClick={() => {
                onClick && onClick();
                details?.freespins_total
                  ? cancelFreespins(details?.id)
                  : cancelBonus(details?.id);
              }}
            >
              {bonusContent?.action?.forfeit}
            </Button>
            <Button
              className="w-full"
              color="primary"
              isLoading={
                details?.freespins_total
                  ? activateLoading
                  : activateBonusLoading
              }
              disabled={
                details?.freespins_total
                  ? activateLoading ||
                    !details?.activatable ||
                    details?.stage !== 'issued'
                  : activateBonusLoading || !details?.activatable
              }
              onClick={(e) => {
                e.stopPropagation();
                onClick && onClick();
                if (!details?.activatable) return;
                return details?.freespins_total
                  ? activateSpins(details?.id).then(() => {
                      queryClient.invalidateQueries({
                        queryKey: ['playerFreeSpins'],
                      }),
                        toast({
                          state: 'success',
                          title: toastMessages?.activated_bonus,
                        });
                    })
                  : activateBonus(details?.id).then(() =>
                      toast({
                        state: 'success',
                        title: toastMessages?.activated_bonus,
                      }),
                    );
              }}
            >
              {bonusContent?.action?.activate}
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

FreeSpinsContent.displayName = 'FreeSpinsContent';

export default FreeSpinsContent;
