import dayjs from '@/utils/dayjsconfig';
import { useMemo } from 'react';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Bonus, DepositPreview } from '@/types/bonuses';
import { FreeSpin } from '@/types/freespins';
import client from '@/utils/api';
import { useAuth } from './auth';
import { useAppSelector } from './redux';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { useAnalytics } from '@/hooks/use-analytics';

export const expiredFreeSpinStages = ['canceled', 'expired', 'finished'];
export const expiredBonusStages = ['lost', 'canceled', 'expired', 'wager_done'];

const usePlayerFreeSpins = (enabled = true): UseQueryResult<FreeSpin[]> => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['playerFreeSpins'],
    queryFn: () => client('player/freespins'),
    enabled: enabled && !user?.isLoading && user?.isAuthenticated,
  });
};

const useActivatePlayerFreeSpins = (): UseMutationResult => {
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: (freespinsID: any) => {
      const requestData = viewFiat ? { ce_currency: 'USD' } : {};
      return client(
        `player/freespins/${freespinsID}/activation`,
        requestData,
        'POST',
      );
    },
    onMutate: async (freespinsID) => {
      await queryClient.cancelQueries({ queryKey: ['playerFreeSpins'] });
      const playerFreeSpins = queryClient.getQueryData(['playerFreeSpins']);
      queryClient.setQueryData(['playerFreeSpins'], (old: any[]) => {
        return old?.map((item) => {
          if (item?.id === freespinsID) {
            return {
              ...item,
              activatable: false,
              stage: 'activated',
              cancelable: true,
            };
          } else {
            return { ...item };
          }
        });
      });
      return { playerFreeSpins };
    },
    onSettled: (res) => {
      track('free_spins_enabled', {
        category: 'user',
        label: res?.freespinsID,
      });
      toast({
        state: 'success',
        title: toastMessages?.fs_activated,
      });
      // only trigger the invalidation after 1s because SS is not updating the data fast enough
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['playerFreeSpins'] });
      }, 1000);
    },
    onError: (err, newEl, context) => {
      // Handle errors by displaying an error toast
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      queryClient.setQueryData(['playerFreeSpins'], context?.playerFreeSpins);
    },
  });
};

const useCancelPlayerFreeSpins = (): UseMutationResult => {
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: (id: any) => {
      return client(`player/freespins/${id}`, {}, 'DELETE').then((res) => {
        track('bonus_forfeited', {
          category: 'user',
          label: id,
        });
        return res;
      });
    },
    onSuccess: () => {
      // Display success message
      toast({
        state: 'success',
        title: toastMessages?.fs_canceled,
      });

      queryClient.invalidateQueries({ queryKey: ['playerFreeSpins'] });
      queryClient.invalidateQueries({ queryKey: ['bonusesHistory'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

const usePlayerBonuses = (enabled = true) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['playerBonuses'],
    queryFn: () => client('player/bonuses'),
    enabled: enabled && !user?.isLoading && user?.isAuthenticated,
  });
};

const useBonusesPreview = () => {
  return useQuery({
    queryKey: ['bonusesPreview'],
    queryFn: () => client('bonuses/deposit'),
  });
};

const useActivatePlayerBonus = (): UseMutationResult => {
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: (bonusID: any) => {
      return client(`player/bonuses/${bonusID}/activation`, {}, 'POST').then(
        (res) => {
          track('bonus_enabled', {
            category: 'user',
            label: bonusID,
          });
          return res;
        },
      );
    },
    onMutate: async (bonusID) => {
      await queryClient.cancelQueries({ queryKey: ['playerBonuses'] });
      const playerBonuses = queryClient.getQueryData(['playerBonuses']);
      queryClient.setQueryData(['playerBonuses'], (old: any[]) => {
        return old?.map((item) => {
          if (item?.id === bonusID) {
            return {
              ...item,
              activatable: false,
              stage: 'activated',
              cancelable: true,
            };
          } else {
            return { ...item };
          }
        });
      });
      return { playerBonuses };
    },
    onSettled: (res) => {
      toast({
        state: 'success',
        title: toastMessages?.bonus_activated,
      });
      // only trigger the invalidation after 1s because SS is not updating the data fast enough
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['playerBonuses'] });
      }, 1000);
    },
    onError: (err, newEl, context) => {
      // Handle errors by displaying an error toast
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      queryClient.setQueryData(['playerBonuses'], context?.playerBonuses);
    },
  });
};

const useCancelPlayerBonus = (): UseMutationResult => {
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: (id: any) => {
      return client(`player/bonuses/${id}`, {}, 'DELETE').then((res) => {
        track('bonus_forfeited', {
          category: 'user',
          label: id,
        });
        return res;
      });
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.bonus_canceled,
      });
      queryClient.invalidateQueries({ queryKey: ['playerBonuses'] });
      queryClient.invalidateQueries({ queryKey: ['bonusesHistory'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

const useActivateCouponBonus = () => {
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (couponCode: string) => {
      return client(`bonuses/coupon`, { coupon_code: couponCode }, 'POST');
    },
    onSuccess: (data) => {
      if (data?.status && data.status === 'successfully_activated') {
        toast({
          state: 'success',
          title: toastMessages?.bonus_activated,
        });
        queryClient.invalidateQueries({ queryKey: ['playerBonuses'] });
        queryClient.invalidateQueries({ queryKey: ['playerFreeSpins'] });
      } else if (data?.status && data.status === 'failed_to_activate') {
        toast({
          state: 'error',
          title: toastMessages?.bonus_not_activated,
        });
      }
    },
    onError: (error) => {
      toast({
        state: 'error',
        title: toastMessages?.general_error || error.message,
      });
    },
  });
};

const useActiveBonusesCount = () => {
  const { data: bonuses } = usePlayerBonuses();
  const { data: freeSpins } = usePlayerFreeSpins();

  const bonusesCount = useMemo(() => {
    if (!bonuses || !freeSpins) {
      return;
    }
    const activeFreeSpins = freeSpins.filter(
      (spin: FreeSpin) => !expiredFreeSpinStages.includes(spin.stage),
    );
    const activeBonuses = bonuses.filter(
      (bonus: Bonus) => !expiredBonusStages.includes(bonus.stage),
    );
    return activeFreeSpins.length + activeBonuses.length;
  }, [bonuses, freeSpins]);

  return bonusesCount;
};

const useFilterBonusesByStages = (stages: string[]) => {
  const { data: bonuses, isPending: bonusesLoading } = usePlayerBonuses();
  const { data: freeSpins, isPending: spinsLoading } = usePlayerFreeSpins();

  const filteredBonuses = useMemo(() => {
    if (!bonuses || !freeSpins) {
      return [];
    }
    const filteredSpins = freeSpins.filter(
      (spin: FreeSpin) => stages.length === 0 || stages.includes(spin.stage),
    );
    const filteredBonuses = bonuses.filter(
      (bonus: Bonus) => stages.length === 0 || stages.includes(bonus.stage),
    );

    return [...filteredSpins, ...filteredBonuses];
  }, [bonuses, freeSpins, stages]);

  return {
    bonuses: filteredBonuses,
    isLoading: bonusesLoading || spinsLoading,
  };
};

const paginateBonuses = async (pageParam: any, bonuses: any = []) => {
  const { offset, limit } = pageParam;
  try {
    const sortedBonuses =
      bonuses &&
      bonuses.sort((current: any, next: any) => {
        return dayjs(current.created_at).isAfter(dayjs(next.created_at))
          ? -1
          : dayjs(current.created_at).isBefore(dayjs(next.created_at))
            ? 1
            : 0;
      });
    const paginatedBonuses = limit
      ? sortedBonuses.slice(offset, offset + limit)
      : sortedBonuses;
    return {
      bonuses: paginatedBonuses,
      totalCount: sortedBonuses && sortedBonuses.length,
    };
  } catch (e) {
    throw e;
  }
};

const filterBonusHistory = (bonuses: any[], pageParams?: any) => {
  return bonuses
    ?.filter((bonus) => {
      return bonus?.group_key !== 'cashback_live_dep' &&
        bonus?.group_key !== 'cashback_vip_dep' &&
        pageParams?.stages &&
        pageParams?.stages?.length > 0
        ? pageParams?.stages?.includes(bonus?.stage)
        : true;
    })
    ?.filter((bonus) => {
      if (pageParams?.dateInterval) {
        const firstDate = dayjs(pageParams?.dateInterval?.split(' - ')[0]);
        const secondDate = dayjs(pageParams?.dateInterval?.split(' - ')[1]);
        if (firstDate && secondDate) {
          return (
            dayjs(bonus?.created_at).isAfter(firstDate) &&
            dayjs(bonus?.created_at).isBefore(secondDate)
          );
        }
        return true;
      } else {
        return true;
      }
    })
    ?.map((item) => {
      return {
        ...item,
        type:
          // @ts-ignore
          (item?.amount_wager_requirement_cents &&
            // @ts-ignore
            item?.amount_wager_requirement_cents !== undefined) ||
          // @ts-ignore
          (item?.amount_cents && item?.amount_cents !== undefined)
            ? 'deposit'
            : 'freespins',
      };
    });
};

const useBonusHistory = (
  pageParams: {
    offset: number;
    limit: number;
    stages?: string[];
    dateInterval?: string;
  },
  enabled = true,
): {
  data: { bonuses: { pages: Bonus[] | FreeSpin[] }; totalCount: number };
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
} => {
  const { bonuses: allBonuses, isLoading } = useFilterBonusesByStages([
    'lost',
    'canceled',
    'expired',
    'wager_done',
  ]);
  // const [bonuses, setBonuses] = useState<any>([]);

  const {
    data: bonuses,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    initialPageParam: pageParams,
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      const filteredResults = filterBonusHistory(allBonuses, pageParams);

      if (lastPageParam?.offset + 7 >= filteredResults.length) return;
      return {
        ...pageParams,
        offset: lastPageParam?.offset + 7,
      };
    },
    queryKey: ['bonusesHistory', pageParams, allBonuses],
    staleTime: 0,
    placeholderData: keepPreviousData,
    queryFn: async ({ queryKey, pageParam }) => {
      const filteredResults = filterBonusHistory(allBonuses, pageParams);

      const bonusesRes = await paginateBonuses(pageParam, filteredResults);
      if (bonusesRes.bonuses.length === 0) {
        return;
      }
      return bonusesRes?.bonuses;
    },
    enabled,
    refetchOnMount: true,
  });
  return {
    // @ts-ignore
    data: { bonuses, totalCount: 0 },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading: isPending || isLoading,
    isFetching,
  };
};

const useAllBonusHistory = (enabled = true) => {
  const { bonuses: allBonuses } = useFilterBonusesByStages([
    'lost',
    'canceled',
    'expired',
    'wager_done',
  ]);
  return useQuery({
    queryKey: ['allBonusesHistory', allBonuses],
    queryFn: ({ queryKey }) => {
      return allBonuses;
    },
    enabled,
    refetchOnMount: true,
  });
};

const useDepositPreviewBonuses = (
  options?: Omit<
    UseQueryOptions<any, any, any, any>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
): UseQueryResult<DepositPreview[]> => {
  const { user } = useAuth();
  return useQuery({
    queryKey: [
      'depositPreviewBonuses',
      options,
      user?.currentUser?.deposit_bonus_code,
    ],
    queryFn: () => client('bonuses/deposit'),
    staleTime: 0,
    ...options,
    retry: false,
  });
};

const useRegistrationPreviewBonuses = (): UseQueryResult<DepositPreview[]> => {
  return useQuery({
    queryKey: ['registrationPreviewBonuses'],
    queryFn: () => client('bonuses/registration'),
  });
};

const useSetPreviewBonusCoupon = () => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (couponCode: string) => {
      try {
        return client(
          `player/set_bonus_code`,
          { deposit_bonus_code: couponCode },
          'PATCH',
        );
      } catch (e) {
        throw e;
      }
    },
    onMutate: async (code: string) => {
      await queryClient.cancelQueries({ queryKey: ['currentUser'] });
      const prevCurrentUser = queryClient.getQueryData(['currentUser']);
      queryClient.setQueryData(['currentUser'], (old: any) => {
        return { ...old, deposit_bonus_code: code };
      });
      return { prevCurrentUser };
    },
    onError: (err, variables, context: any) => {
      queryClient.setQueryData(['currentUser'], context.prevCurrentUser);
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['player'] });
      queryClient.invalidateQueries({ queryKey: ['depositPreviewBonuses'] });
    },
  });
};

const useClearPreviewBonusCoupon = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      try {
        return client(`player/clear_bonus_code`, {}, 'DELETE');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['depositPreviewBonuses'] });
      queryClient.invalidateQueries({ queryKey: ['player'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

const useUpdateBonusSettings = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (canIssue) => {
      try {
        return client(
          `player/update_bonus_settings`,
          { can_issue: canIssue },
          'PATCH',
        );
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['depositPreviewBonuses'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['player'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

export {
  usePlayerFreeSpins,
  usePlayerBonuses,
  useActivatePlayerBonus,
  useActivatePlayerFreeSpins,
  useCancelPlayerBonus,
  useCancelPlayerFreeSpins,
  useActivateCouponBonus,
  useActiveBonusesCount,
  useFilterBonusesByStages,
  useBonusesPreview,
  useBonusHistory,
  useDepositPreviewBonuses,
  useRegistrationPreviewBonuses,
  useSetPreviewBonusCoupon,
  useClearPreviewBonusCoupon,
  useUpdateBonusSettings,
  useAllBonusHistory,
};
