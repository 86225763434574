export const getSnackbarIconStyle = (state: string) => {
  switch (state) {
    case 'info':
      return 'bg-blue-500/10';
    case 'success':
      return 'bg-icons-primary/10';
    default:
      return 'bg-icons-primary/10';
  }
};
