'use client';
import React, {
  useCallback,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSettings from '@/hooks/settings';
import {
  EventPublicWinsPayload,
  BonusPayload,
  PaymentPayload,
  EventAnalyticsPayload,
  FreeSpinsPayload,
  GroupPayload,
  LootboxesPayload,
} from '@/types/realtime';
import { useAuth } from '@/hooks/auth';
import { getPrivateNotificationChannels } from '@/modules/feed/utils';

type Data =
  | EventPublicWinsPayload
  | EventAnalyticsPayload
  | BonusPayload
  | PaymentPayload
  | FreeSpinsPayload
  | GroupPayload
  | LootboxesPayload;

type Message = {
  channel: string;
  uid: string;
  data: Data;
};

type RealtimeContextType = {
  client: WebSocket | undefined;
  isLoading: boolean;
  subscriptions: any[] | undefined;
  updateSubscriptions?: any;
  setCentId?: any;
  centId?: string;
  subscribe: (sub: {
    method: string;
    params: {
      channel: string;
    };
  }) => void;
  unsubscribe: (sub: {
    method: string;
    params: {
      channel: string;
    };
  }) => void;
};

export const RealtimeContext = createContext<RealtimeContextType>(
  {} as RealtimeContextType,
);

const RealtimeProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const [connected, setConnected] = useState(false);
  const { isPending: settingsLoading, data: settings } = useSettings();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [centId, setCentId] = useState<string | undefined>();
  const [client, setClient] = useState<WebSocket | undefined>();

  const updateSubscriptions = useCallback((newSubs: any) => {
    setSubscriptions(newSubs);
  }, []);

  useEffect(() => {
    if (settingsLoading || isLoading || client || !settings?.cent || connected)
      return;
    const connectCentrifuge = async () => {
      setIsLoading(true);
      const ws = new WebSocket(`${settings?.cent?.url}/connection/websocket`);
      ws.onopen = () => {
        //@ts-ignore
        const connectPayload = {
          method: 'connect',
          params: {
            ...settings?.cent,
          },
        };
        ws.send(JSON.stringify(connectPayload));
        const subscription = {
          method: 'subscribe',
          params: {
            channel: 'public:wins',
          },
        };
        const jackpotSub = {
          method: 'subscribe',
          params: {
            channel: 'public:jackpots_changes',
          },
        };

        ws.send(JSON.stringify(subscription));
        ws.send(JSON.stringify(jackpotSub));
        setCentId(settings?.cent.user);
        setClient(ws);
        setIsLoading(false);
        setConnected(true);
      };
    };
    connectCentrifuge();
  }, [
    client,
    isLoading,
    settings?.cent,
    settingsLoading,
    updateSubscriptions,
    connected,
  ]);

  const subscribe = useCallback(
    (sub: { method: string; params: { channel: string } }) => {
      if (!client) {
        console.error('Cannot subscribe. WebSocket is undefined');
        return;
      }
      client.send(JSON.stringify(sub));
    },
    [client],
  );

  const unsubscribe = useCallback(
    (unsub: { method: string; params: { channel: string } }) => {
      if (!client) {
        console.error('Cannot unsubscribe. WebSocket is undefined');
        return;
      }
      client.send(JSON.stringify(unsub));
    },
    [client],
  );
  useEffect(() => {
    if (!client || settingsLoading || user?.isLoading || user?.isFetching)
      return;
    if (!user?.isAuthenticated && subscriptions?.length > 0) {
      setCentId(undefined);
      updateSubscriptions([]);
      subscriptions?.map((sub: any) =>
        unsubscribe({
          method: 'unsubscribe',
          params: {
            channel: sub?.channel,
          },
        }),
      );
      return;
    }
    if (user?.isAuthenticated && subscriptions?.length === 0) {
      const privateChannels = getPrivateNotificationChannels(
        settings?.cent?.user,
      );
      privateChannels &&
        privateChannels.map(({ channel }: any) => {
          return subscribe({
            method: 'subscribe',
            params: {
              channel,
            },
          });
        });
      updateSubscriptions(privateChannels);
      return;
    }
  }, [
    centId,
    isLoading,
    settings?.cent?.user,
    settingsLoading,
    user?.isLoading,
    user?.isAuthenticated,
    subscriptions,
    updateSubscriptions,
    subscribe,
    unsubscribe,
    client,
    user?.isFetching,
  ]);
  const memoValue = useMemo(() => {
    return {
      isLoading,
      client,
      subscribe,
      unsubscribe,
      updateSubscriptions,
      centId,
      setCentId,
      subscriptions,
    };
  }, [
    centId,
    client,
    isLoading,
    subscribe,
    subscriptions,
    unsubscribe,
    updateSubscriptions,
  ]);

  return (
    <RealtimeContext.Provider value={memoValue}>
      {children}
    </RealtimeContext.Provider>
  );
};

const useRealtime = () => {
  const context = React.useContext(RealtimeContext);
  if (context === undefined) {
    throw new Error(`useRealtime must be used within a RealtimeProvider`);
  }
  return context;
};

export default RealtimeProvider;

export { useRealtime };
