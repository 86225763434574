import React, { useMemo } from 'react';
import { getIconByCurrency } from '@/utils/helpers';
import { cn } from '@/utils/style';
import { CryptoTooltipProps } from './types';
import { Tooltip } from '@purposeinplay/core-v2';
import { useAppSelector } from '@/hooks/redux';

const CryptoTooltip: React.FC<CryptoTooltipProps> = ({
  children,
  cryptoAmount,
  cryptoCode,
  alwaysShow = false,
  valueStyle,
  ...props
}) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const tooltipValue = useMemo(() => {
    return (
      <div className="flex flex-row items-center space-x-1" {...props}>
        <span className={cn([valueStyle && valueStyle])}>{cryptoAmount}</span>
        <div className="shrink-0">
          {getIconByCurrency(cryptoCode?.toLowerCase())}
        </div>
      </div>
    );
  }, [cryptoAmount, cryptoCode, props, valueStyle]);

  return viewFiat || alwaysShow ? (
    <Tooltip
      styleOptions={{
        arrowStyles: '!z-[99999]',
        popperStyles: '!z-[99999]',
      }}
      placement="top"
      displayOn="hover"
      trigger={
        <div className={cn([viewFiat && 'cursor-pointer'])} {...props}>
          {tooltipValue}
        </div>
      }
    >
      {children}
    </Tooltip>
  ) : (
    tooltipValue
  );
};

export default CryptoTooltip;
