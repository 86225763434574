import React from 'react';
import { Heading, ListComponent, Text } from '@purposeinplay/core-v2';
import ContentWrapper from './content-wrapper';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { getSnackbarIconStyle } from '@/components/MediaComponent/utils';
import { cn } from '@/utils/style';
type Props = {
  icon: React.ReactNode;
  details: any;
};

const LevelUpContent: React.FC<Props> = React.memo(({ icon, details }) => {
  const { t } = useTranslation();
  const content = t('notifications') as NotificationsContentType;

  return (
    <div className="flex flex-col items-center">
      <div
        className={cn([
          'relative mb-spacing-sm mr-spacing-xs flex h-10 w-10 items-center justify-center rounded-radius-md p-padding-sm',
          getSnackbarIconStyle('info'),
        ])}
      >
        {icon}
      </div>
      <Heading as="h2" className="mb-7 text-xl font-medium">
        {details?.name}
      </Heading>
      <ListComponent
        stylingOptions={{
          containerStyles:
            'hover:bg-surface-subdued hover:cursor-auto bg-surface-subdued',
          valueStyles: 'text-right',
          labelStyles: 'text-text-subdued',
        }}
        label={content?.notification_types?.level_up?.min_points}
        value={details?.conditions[0]?.persistent_comp_points?.min}
      />
      <ListComponent
        stylingOptions={{
          containerStyles:
            'hover:bg-surface-subdued hover:cursor-auto bg-surface-subdued',
          valueStyles: 'text-right',
          labelStyles: 'text-text-subdued',
        }}
        label={content?.notification_types?.level_up?.max_points}
        value={details?.conditions[0]?.persistent_comp_points?.max}
      />
    </div>
  );
});

LevelUpContent.displayName = 'LevelUpContent';

export default LevelUpContent;
