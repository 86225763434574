import React, { memo } from 'react';
import { getIconByCurrency } from '@/utils/helpers';
import Image from 'next/image';
import DepositSVG from 'public/assets/notifications/dollar.svg';
import WalletSVG from 'public/assets/notifications/dollar.svg';
import LevelSVG from 'public/assets/notifications/level-icon.svg';
import FreeSpinsSVG from 'public/assets/notifications/bonus.svg';
import BonusSVG from 'public/assets/notifications/bonus.svg';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import PaymentsContent from './components/payments-content';
import LevelUpContent from './components/level-up-content';
import FreeSpinsContent from './components/free-spins-content';
import JackpotSVG from 'public/assets/notifications/jackpot.svg';
import JackpotContent from './components/jackpot-content';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

interface TitleProps {
  type: string;
  message: number;
  currency: string;
  info: string;
  viewFiat: boolean;
  content: NotificationsContentType['notification_messages'];
}

export const Title: React.FC<TitleProps> = memo(
  ({ type, message, currency = '', info = '', viewFiat = false, content }) => {
    const { formatCurrency } = useCurrencyFormatter();
    let value = (
      viewFiat
        ? message
        : formatCurrency(message, currency, { style: 'decimal' })
    ) as string;
    if (currency === 'USDT') {
      value = (
        viewFiat
          ? message
          : formatCurrency(message, currency, { style: 'decimal' })
      ) as string;
    }

    switch (type) {
      case 'deposit':
        const depositDescription = content?.deposit?.description;

        const depositValue = !viewFiat ? (
          <>&nbsp;{removeAfterSpace(value)}</>
        ) : (
          <>&nbsp;{value}</>
        );
        const depositIcon = (
          <>&nbsp;{getIconByCurrency(currency.toLowerCase())}</>
        );
        return (
          <span className="[img]:inline-block flex flex-row flex-wrap items-center">
            {depositDescription?.split('{{depositAmount}}')[0]}
            <span className="flex flex-row items-center">
              {depositValue}
              {depositIcon}
            </span>
            {depositDescription?.split('{{depositAmount}}')[1]}
          </span>
        );
      case 'withdraw':
        const withdrawDescription = content?.withdraw?.description;
        const withdrawValue = !viewFiat ? (
          <>&nbsp;{removeAfterSpace(value)}</>
        ) : (
          <>&nbsp;{value}</>
        );
        const withdrawIcon = (
          <>&nbsp;{getIconByCurrency(currency.toLowerCase())}</>
        );

        return (
          <span className="[img]:inline-block flex flex-row flex-wrap items-center">
            {withdrawDescription?.split('{{withdrawAmount}}')[0]}
            <span className="flex flex-row items-center">
              {withdrawValue}
              {withdrawIcon}
            </span>
            {withdrawDescription?.split('{{withdrawAmount}}')[1]}
          </span>
        );
      case 'level': {
        return content?.level?.description?.replace('{{level}}', info);
      }
      case 'freeSpins':
        return content?.freeSpins?.description?.replace(
          '{{fs}}',
          message?.toString(),
        );
      case 'bonus':
        const bonusDescription = content?.bonus?.description;
        const bonusValue = !viewFiat ? (
          <>&nbsp;{removeAfterSpace(value)}</>
        ) : (
          <>&nbsp;{value}</>
        );
        const bonusIcon = (
          <>&nbsp;{getIconByCurrency(currency.toLowerCase())}</>
        );
        return (
          <span className="[img]:inline-block flex flex-row flex-wrap items-center">
            {bonusDescription?.split('{{bonusValue}}')[0]}
            <span className="flex flex-row items-center">
              {bonusValue}
              {bonusIcon}
            </span>
            {bonusDescription?.split('{{bonusValue}}')[1]}
          </span>
        );

      case 'lootboxes':
        return content?.lootboxes?.description;
      case 'jackpot':
        return content?.jackpot?.description;
      default:
        return content?.default?.description;
    }
  },
);

Title.displayName = 'Title';

export const getIcon = (type: string) => {
  switch (type) {
    case 'deposit':
      return (
        <Image
          src="/assets/notifications/dollar.svg"
          width={20}
          height={20}
          alt="Deposit icon"
        />
      );
    case 'withdraw':
      return (
        <Image
          src="/assets/notifications/dollar.svg"
          width={20}
          height={20}
          alt="Withdraw icon"
        />
      );
    case 'level':
      return (
        <Image
          src="/assets/notifications/level-icon.svg"
          width={20}
          height={20}
          alt="Level icon"
        />
      );
    case 'freeSpins':
      return (
        <Image
          src="/assets/notifications/bonus.svg"
          width={20}
          height={20}
          alt="Free-spins icon"
        />
      );
    case 'bonus':
      return (
        <Image
          src="/assets/notifications/bonus.svg"
          width={20}
          height={20}
          alt="Bonus icon"
        />
      );
    case 'lootboxes':
      return (
        <Image
          src="/assets/notifications/bonus.svg"
          width={20}
          height={20}
          alt="Lootboxes icon"
        />
      );
    case 'empty':
      return (
        <Image
          src="/assets/notifications/bonus.svg"
          width={20}
          height={20}
          alt="Lootboxes icon"
        />
      );
    case 'jackpot':
      return (
        <Image
          src="/assets/notifications/jackpot.svg"
          loader={DEFAULT_IMAGE_LOADER}
          width={20}
          height={20}
          alt="Jackpot icon"
        />
      );
    default:
      <Image
        src="/assets/wallet-icon.svg"
        className="h-full w-full"
        fill
        alt="Wallet icon"
      />;
  }
};

export const getTitleModal = (
  type: string,
  content: NotificationsContentType['modal'],
) => {
  switch (type) {
    case 'deposit':
      return content?.titles?.deposit;
    case 'withdraw':
      return content?.titles?.withdraw;
    case 'level':
      return content?.titles?.level;
    case 'freeSpins':
      return content?.titles?.freeSpins;
    case 'bonus':
      return content?.titles?.bonus;
    case 'jackpot':
      return content?.titles?.jackpot;
    default:
      'Modal';
  }
};

export const getNotificationIconByType = (type: string) => {
  switch (type) {
    case 'deposit':
      return <DepositSVG />;
    case 'withdraw':
      return <WalletSVG />;
    case 'level':
      return <LevelSVG />;
    case 'freeSpins':
      return <FreeSpinsSVG />;
    case 'bonus':
      return <BonusSVG />;
    case 'lootboxes':
      return <BonusSVG />;
    case 'jackpot':
      return <JackpotSVG />;
    default:
      'M';
  }
};

export const getModalContent = (
  type: string,
  notification: any,
  onClick: any,
  viewFiat: boolean,
  fiatAmount: any,
) => {
  switch (type) {
    case 'deposit':
      return (
        <PaymentsContent
          icon={getNotificationIconByType('deposit')}
          details={notification}
          viewFiat={viewFiat}
          fiatAmount={fiatAmount}
        />
      );
    case 'withdraw':
      return (
        <PaymentsContent
          icon={getNotificationIconByType('withdraw')}
          details={notification}
          viewFiat={viewFiat}
          fiatAmount={fiatAmount}
        />
      );
    case 'level':
      return (
        <LevelUpContent
          icon={getNotificationIconByType('level')}
          details={notification}
        />
      );
    case 'freeSpins':
      return (
        <FreeSpinsContent
          icon={getNotificationIconByType('freeSpins')}
          onClick={onClick}
          details={notification}
          viewFiat={viewFiat}
          fiatAmount={fiatAmount}
        />
      );
    case 'bonus':
      return (
        <FreeSpinsContent
          icon={getNotificationIconByType('bonus')}
          onClick={onClick}
          details={notification}
          viewFiat={viewFiat}
          fiatAmount={fiatAmount}
        />
      );
    case 'jackpot':
      return <JackpotContent details={notification} viewFiat={viewFiat} />;
    default:
      'Modal';
  }
};

const getTypeByChannel = (chanName: string, data: any) => {
  switch (chanName) {
    case 'bonuses_changes': {
      return 'bonus';
    }
    case 'freespins_changes': {
      return 'freeSpins';
    }
    case 'groups_updates_separated_by_type': {
      return 'level';
    }
    case 'lootboxes_changes': {
      return 'lootboxes';
    }
    case 'payments_changes': {
      return data?.action === 'deposit' ? 'deposit' : 'withdraw';
    }
    case 'analytics': {
      return 'analytics';
    }
    case 'jackpot_win_award': {
      return 'jackpot';
    }
    // case 'personal_notifications': {
    //   return 'personal_notifications';
    // }
    default: {
      return 'undefined';
    }
  }
};

export const processUnreadNotification = (message: any, userLevel: string) => {
  const data = message?.data;
  const chanName = message?.channel?.split('#')[0];
  const type = getTypeByChannel(chanName, data);
  let payload = { ...data, type, read: false };
  //exclude boosters
  if (
    chanName === 'groups_updates_separated_by_type' &&
    data?.added?.length > 0 &&
    (data?.added[0]?.id?.includes('booster') ||
      data?.removed[0]?.id?.includes('booster'))
  ) {
    payload = undefined;
  }

  // filter only issued bonuses & FS
  if (type === 'freeSpins' || type === 'bonus') {
    if (
      (data?.stage === 'issued' ||
        data?.stage === 'handle_bets' ||
        data?.stage === 'wait') &&
      data?.activatable
    ) {
      payload = { ...data, type, read: false };
    } else {
      payload = undefined;
    }
  }
  // filter only issued lootboxes
  if (type === 'lootboxes') {
    if (data?.stage === 'issued') {
      payload = { ...data, type, read: false };
    } else {
      payload = undefined;
    }
  }

  // handle jackpot win notifications
  if (type === 'jackpot') {
    if (data?.stage === 'issued' && data?.activatable) {
      payload = { ...data, type, read: false };
    } else {
      payload = undefined;
    }
  }

  // filter only loyalty levels for groups updates since it returns all groups
  if (
    chanName === 'groups_updates_separated_by_type' &&
    data?.added?.length > 0
  ) {
    const addedLevel = data?.added?.find(
      (el: any) => el?.id?.includes('loyalty') && el?.id?.includes('lvl'),
    );

    const removedLevel = data?.removed?.find(
      (el: any) => el?.id?.includes('loyalty') && el?.id?.includes('lvl'),
    );

    if (addedLevel && removedLevel) {
      const addedIdParts = addedLevel.id.split(/[_:]/);
      const removedIdParts = removedLevel.id.split(/[_:]/);

      const newLevel = addedIdParts[addedIdParts.length - 1];
      const oldLevel = removedIdParts[removedIdParts.length - 1];

      const isValidLevelUpgrade =
        !isNaN(Number(newLevel)) &&
        !isNaN(Number(oldLevel)) &&
        Number(newLevel) > Number(oldLevel);

      if (isValidLevelUpgrade) {
        payload = {
          ...addedLevel,
          type: 'level',
          read: false,
          info: newLevel,
        };
      } else {
        payload = undefined;
      }
    } else {
      payload = undefined;
    }
  }

  return { payload, type, chanName };
};

export function removeAfterSpace(str: string) {
  const index = str.indexOf(' ');
  if (index !== -1) {
    return str.substring(0, index);
  }
  return str;
}

export const TOAST_ONLY_CHANNELS = ['level', 'withdraw', 'deposit'];

export const mapStageToStatus = (stage: string, activatable: boolean) => {
  switch (stage) {
    case 'wager_done':
      return 'Wager done';
    case 'lost':
      return 'Lost';
    case 'expired':
      return 'Expired';
    case 'activated':
      return 'Active';
    case 'played':
      return 'Played';
    case 'finished':
      return 'Played';
    case 'handle_bets':
      return activatable ? 'Not activated' : 'Active';
    case 'canceled':
      return 'Canceled';
    case 'wait':
      return 'Waiting';
    case 'issued':
      return 'Not Activated';
    default:
      return 'Loading';
  }
};

export const getPrivateNotificationChannels = (user: number | string) => {
  if (!user) return;
  return [
    { channel: `analytics#${user}`, type: 'analytics' },
    { channel: `bonuses_changes#${user}`, type: 'bonus' },
    { channel: `payments_changes#${user}`, type: 'payment' },
    {
      channel: `freespins_changes#${user}`,
      type: 'freeSpins',
    },
    {
      channel: `lootboxes_changes#${user}`,
      type: 'lootboxes',
    },
    {
      channel: `groups_updates_separated_by_type#${user}`,
      type: 'level',
    },
    {
      channel: `comps_award#${user}`,
      type: 'loyalty',
    },
    {
      channel: `jackpot_win_award#${user}`,
      type: 'jackpot_win_award',
    },
    // {
    //   channel: `personal_notifications#${user}`,
    //   type: 'personal_notifications',
    // },
  ];
};
