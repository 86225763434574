import { cn } from '@/utils/style';
import React from 'react';

interface Props {
  small?: boolean;
  extraSmall?: boolean;
  invert?: boolean;
  className?: string;
  tournament?: any;
  stylingOptions?: {
    unit?: string;
    unitLabel?: string;
    container?: string;
    dot?: string;
  };
  isLoading?: boolean;
  units?: {
    days?: string | number;
    minutes?: string | number;
    hours?: string | number;
    seconds?: string | number;
  };
  hasDays?: boolean;
}

const TimeUnit = ({
  value,
  unit,
  unitStyle,
  small,
  extraSmall,
  isLoading,
  invert = false,
  className,
}: any) => {
  return (
    <div
      className={cn([
        'flex flex-col items-center text-text-default',
        invert && `text-white dark:text-white`,
      ])}
    >
      <div
        className={cn([
          'mb-1 flex h-10 w-10 items-center justify-center rounded-lg bg-surface-default text-text-default',
          small && `h-8 w-8`,
          extraSmall && `text-base`,
          className,
        ])}
      >
        {isLoading || !value ? '00' : value}
      </div>
      <div
        className={cn([
          'text-xs uppercase text-text-subdued',
          invert && `text-white dark:text-white`,
          unitStyle,
        ])}
      >
        {unit}
      </div>
    </div>
  );
};

const Dots = ({ small, extraSmall, invert, className }: any) => {
  return (
    <span
      className={cn([
        'p-0.5 font-black',
        invert && `text-white dark:text-white`,
        className,
      ])}
    >
      :
    </span>
  );
};

const Timeleft = ({
  hasDays = true,
  units,
  invert,
  extraSmall,
  small,
  className,
  stylingOptions,
  isLoading,
  ...props
}: Props) => {
  const timeLoading =
    !units?.days &&
    units?.days === 0 &&
    units?.hours === 0 &&
    units?.minutes === 0 &&
    units?.seconds === 0;
  return (
    <div
      className={cn([
        'flex flex-row justify-center space-x-[1px] font-medium',
        className,
        stylingOptions?.container,
      ])}
      {...props}
    >
      <TimeUnit
        isLoading={timeLoading}
        value={units?.days}
        extraSmall={extraSmall}
        small
        className={stylingOptions?.unit}
        unitStyle={stylingOptions?.unitLabel}
        invert={invert}
        unit="Days"
      />
      <Dots
        invert={invert}
        extraSmall={extraSmall}
        small
        className={stylingOptions?.dot}
      />

      <TimeUnit
        isLoading={timeLoading}
        value={units?.hours}
        extraSmall={extraSmall}
        className={stylingOptions?.unit}
        unitStyle={stylingOptions?.unitLabel}
        small
        invert={invert}
        unit="HRS"
      />
      <Dots
        invert={invert}
        extraSmall={extraSmall}
        small
        className={stylingOptions?.dot}
      />
      <TimeUnit
        isLoading={timeLoading}
        value={units?.minutes}
        extraSmall={extraSmall}
        className={stylingOptions?.unit}
        unitStyle={stylingOptions?.unitLabel}
        small
        invert={invert}
        unit="Mins"
      />
      <Dots
        invert={invert}
        extraSmall={extraSmall}
        small
        className={stylingOptions?.dot}
      />
      <TimeUnit
        isLoading={timeLoading}
        value={units?.seconds}
        className={stylingOptions?.unit}
        unitStyle={stylingOptions?.unitLabel}
        extraSmall={extraSmall}
        small
        unit="Sec"
        invert={invert}
      />
    </div>
  );
};

export default Timeleft;
